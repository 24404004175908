import { useEffect, useRef, useState } from "react";
import {
	IconKeyboard,
	IconPlayerPlay,
	IconPlayerStop,
	IconSend,
	IconX,
} from "@tabler/icons-react";
import EmojiPicker from "emoji-picker-react";
import useOnClickOutside from "../../hooks/useClickoutside";
import useSendMessage from "../../hooks/useSendMessage";
import { cn } from "../../lib/utils";
import useChatStore from "../../store";
import { Input } from "../shadcn/input";
import { Toggle } from "../shadcn/toggle";
import MediaRecorder from "audio-recorder-polyfill";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BouncingDots from "../dots-animation/BouncingDots";
import FaceSmileIcon from "../icons/FaceSmileIcon";
import MicrophoneIcon from "../icons/MicrophoneIcon";
import PhotoIcon from "../icons/PhotoIcon";

interface IChatInput {
	isTestChat: boolean;
	setIsVoiceOpened: React.Dispatch<React.SetStateAction<boolean>>;
	themeData: ICreateThemeData;
	isVideoRecordingPressed: boolean;
	handleStartVideoRecording: () => void;
	isVideoRecordingActive: boolean;
}

export enum EVideoStatus {
	IDLE = 'idle',
	RECORDING = 'recording',
	PAUSED = 'paused',
	STOPPED = 'stopped',
	ACQUIRING_MEDIA = 'acquiring_media',
}

function ChatInput({ isTestChat, setIsVoiceOpened, themeData, isVideoRecordingPressed,
	  isVideoRecordingActive }: IChatInput) {
	const { inputsDisabled, setInputsDisabled, addMessageAndStartCreateAsistant, messages, currentProjectToken } = useChatStore((state) => state);
	const emojiPickerRef = useRef<HTMLDivElement>(null);
	const sendMessage = useSendMessage();
	const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
	const [isAudioRecordingPressed, setIsAudioRecordingPressed] = useState(false);
	const [inputState, setInputState] = useState("");
	const [isVoiceBoxOpen, setIsVoiceBoxOpen] = useState(false);
	const [volume, setVolume] = useState<number>(0);
	const requestRef = useRef<number>();
	const volumeRef = useRef<HTMLDivElement>(null);
	const [audioAllowed, setAudioAllowed] = useState<boolean>(true);
	const [audioReadyToSend, setAudioReadyToSend] = useState(false);
	const [cancelRecording, setCancelRecording] = useState(false);
	const recorder = useRef<MediaRecorder | null>(null);
	const recorderAudioContext = useRef<MediaRecorder | null>(null);
	const chunks = useRef<Blob[]>([]);
	const [showVoiceMainInput, setShowVoiceMainInput] = useState(true);

	const setRecorderAudio = useRef((e: any) => {
		chunks.current.push(e.data);
	});

	const tokenRef = useRef<null | string>(null);

	// audio
	const stopRecordingChunksAudio = useRef(async () => {
		const blob = new Blob(chunks.current, { type: "audio/wav" });
		try {
			await sendMessage({
				file: blob,
				text: "",
				onError: console.error,
				projectToken: tokenRef.current
			});
		} catch (e) {
			console.error(e);
		}
		addMessageAndStartCreateAsistant({
			_id: Math.random().toString(),
			role: "user",
			text: "",
			loading: false,
			data: [{ type: "audio", url: URL.createObjectURL(blob), isUser: true }],
			created_at: new Date().toISOString(),
		});
		chunks.current = [];
		recorder.current?.stream.getTracks().forEach((track) => track.stop());
		recorderAudioContext.current?.stream.getTracks().forEach((track) => track.stop());
	});

	const getPermissionAudio = async () => {
		if (!navigator?.mediaDevices?.getUserMedia) {
			return toast.error("Unfortunately, your browser lacks media recording support.");
		}
		if (!isVoiceBoxOpen) {
			recorder.current?.removeEventListener("dataavailable", setRecorderAudio?.current);
			recorder.current?.removeEventListener("stop", stopRecordingChunksAudio?.current);
		}
		try {
			const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
			recorder.current = new MediaRecorder(stream);
			recorder.current?.addEventListener("dataavailable", setRecorderAudio?.current);
			recorder.current?.addEventListener("stop", stopRecordingChunksAudio?.current);
		} catch {
			toast.info("Enable microphone access in your browser settings to send audio messages.");
			setAudioAllowed(false);
		}
	};

	useOnClickOutside(emojiPickerRef, () => setIsEmojiPickerOpen(false));

	// audio
	const startRecordingAudio = async (isReady: boolean) => {
		await getPermissionAudio();
		await startAudioContext();
		if (!recorder.current) return;
		try {
			recorder.current.start();
			setIsAudioRecordingPressed(isReady);
		} catch (err) {
			console.error("Error recording audio:", err);
		}
	};

	const stopRecordingAudio = () => {
		if (!recorder.current) return;
		recorder.current?.pause();
		setAudioReadyToSend(true);
		setIsAudioRecordingPressed(false);
	};

	const sendRecordingAudio = () => {
		if (!recorder.current) return;
		recorder.current?.stop();
		recorderAudioContext.current?.stop();
		setAudioReadyToSend(false);
		setIsAudioRecordingPressed(false);
		setIsVoiceOpened(false);
		setIsVoiceBoxOpen(false);
	};

	const inputRef = useRef<HTMLInputElement>(null);

	const handleSubmitText = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const text = e.currentTarget.message.value;
		if (!text) return;
		const newMessage = {
			_id: Math.random().toString(),
			loading: false,
			text,
			data: [],
			role: "user",
			seen: false,
			created_at: new Date().toISOString(),
		} as TMessageData;
		setInputsDisabled(true);
		await sendMessage({
			text,
			onError: () => { },
		});
		addMessageAndStartCreateAsistant(newMessage);
		setInputState("");
	};

	const openVoiceBoxFunction = () => {
		if (audioAllowed) {
			setIsVoiceOpened(true);
			setIsVoiceBoxOpen(true);
			removeInputDataOnMessageSent();
		}
	};

	const handleCancelVoiceMessage = () => {
		recorder.current?.stream.getTracks().forEach((track) => track.stop());
		recorderAudioContext.current?.stream.getTracks().forEach((track) => track.stop());
		recorder.current?.removeEventListener("dataavailable", setRecorderAudio?.current);
		recorder.current?.removeEventListener("stop", stopRecordingChunksAudio?.current);

		recorder.current?.stop();
		recorderAudioContext.current?.stop();

		setAudioReadyToSend(false);
		setIsAudioRecordingPressed(false);
		setIsVoiceOpened(false);
		setIsVoiceBoxOpen(false);
		chunks.current = [];
	};

	const handleFileupload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (!file) return;

		let messageType: "image" | "video" | null = null;
		removeInputDataOnMessageSent();
		if (file.type.startsWith("image")) {
			messageType = "image";
		} else if (file.type.startsWith("video")) {
			messageType = "video";
		}

		if (!messageType) return;

		const newMessage = {
			_id: Math.random().toString(),
			loading: false,
			text: "",
			data: [{ type: messageType, url: URL.createObjectURL(file), isUser: true }],
			role: "user",
			seen: false,
			created_at: new Date().toISOString(),
		} as TMessageData;

		setInputsDisabled(true);
		sendMessage({
			file,
			text: "",
			onError: () => { },
		});
		addMessageAndStartCreateAsistant(newMessage);

		e.target.value = "";
	};

	// audio voice volume recognition
	const startAudioContext = async () => {
		try {
			const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
			const audioContext = new AudioContext();
			const analyser = audioContext.createAnalyser();

			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			setAudioAllowed(true);

			const source = audioContext.createMediaStreamSource(stream);
			recorderAudioContext.current = new MediaRecorder(stream);
			source.connect(analyser);
			analyser.fftSize = 256;

			const bufferLength = analyser.frequencyBinCount;
			const dataArray = new Uint8Array(bufferLength);

			const updateVolume = () => {
				analyser.getByteFrequencyData(dataArray);
				const maxVolume = Math.max(...Array.from(dataArray));
				const normalizedVolume = maxVolume / 255;
				setVolume(normalizedVolume);
				requestRef.current = requestAnimationFrame(updateVolume);
			};

			updateVolume();
		} catch (error) {
			setAudioAllowed(false);
		}
	};

	const populateInput = () => {
		const textToAutoPopulate = localStorage.getItem('last_user_message');
		setInputState(textToAutoPopulate || '');

		if (textToAutoPopulate) {
			inputRef.current?.focus()
		}
	};

	const removeInputDataOnMessageSent = () => {
		localStorage.removeItem('last_user_message');
	}

	const showTextInput = () => {
		setShowVoiceMainInput(false);
	}

	const showVoiceInput = () => {
		setShowVoiceMainInput(true);
	}

	useEffect(() => {
		if (inputRef.current && !inputsDisabled && !showVoiceMainInput) {
			inputRef.current.focus();
		}
	}, [showVoiceMainInput]);

	useEffect(() => {
		tokenRef.current = currentProjectToken
	}, [currentProjectToken])

	useEffect(() => {
		populateInput();
	}, [messages]);

	useEffect(() => {
		const handleBeforeUnload = () => {
			localStorage.removeItem('last_user_message');
			setInputState('')
		};

		const handleUnload = () => {
			localStorage.removeItem('last_user_message');
			setInputState('')
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		window.addEventListener('unload', handleUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('unload', handleUnload);
		};
	}, []);

	useEffect(() => {
		setIsVoiceOpened(false);
		setIsVoiceBoxOpen(false);
	}, [audioAllowed]);

	const circleWidth = `${80 + volume * 230}px`;

	return (
		<form
			onSubmit={handleSubmitText}
			className={cn(
				isTestChat ? "relative" : "max-w-[997px] mx-auto min500:bg-white fixed flex-col bottom-0 left-0 right-0",
				"flex items-center w-full",
				isVoiceBoxOpen && "max500:px-2"
			)}
		>
			{!showVoiceMainInput ?
				<div
					className={cn("flex w-full gap-2.5",
						!isTestChat && "px-2 pb-3 min500:bg-white", isVoiceBoxOpen && "bg-white opacity-0")}
				>
					<div
						className={cn(
							"flex py-1.5 w-full border-2 px-2.5 rounded-full shadow bg-white",
							inputsDisabled || isVideoRecordingActive || isAudioRecordingPressed || isVoiceBoxOpen ? "!bg-gray-200" : "",
							!isTestChat && 'mt-2.5'
						)}
					>
						{isEmojiPickerOpen && (
							<div ref={emojiPickerRef} className={cn("absolute bottom-full left-0")}>
								<EmojiPicker
									onEmojiClick={(selectedEmoji: { emoji: any }) =>
										setInputState((prev) => `${prev}${selectedEmoji.emoji}`)
									}
									className="w-full"
									lazyLoadEmojis
									skinTonesDisabled
									searchDisabled
								/>
							</div>
						)}
						<button
							disabled={inputsDisabled || isVideoRecordingActive}
							onClick={() => setIsEmojiPickerOpen((prev) => !prev)}
							type="button"
						>
							<FaceSmileIcon className="text-gray-500 size-6" />
						</button>
						<Input
							value={inputState}
							onChange={(e) => setInputState(e.target.value)}
							className="text-base bg-white border-none shadow-none outline-none"
							type="text"
							autoComplete="off"
							name="message"
							disabled={inputsDisabled || isVideoRecordingActive || isAudioRecordingPressed || isVoiceBoxOpen}
							placeholder="Type message"
							ref={inputRef}
						/>
					</div>
					{inputState ? (
						<button
							type="submit"
							disabled={inputsDisabled}
							onClick={removeInputDataOnMessageSent}
							style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
							className={cn(
								"mt-2 flex items-center rounded-full justify-center text-white transition-all ease-in-out min-w-[45px] w-[45px] h-[45px] disabled:opacity-50",
								isTestChat ? 'mt-2' : 'mt-4'
							)}
						>
							<IconSend size={24} />
						</button>
					) : (
						<div className={cn("flex items-center justify-center gap-1 mt-4",
							isTestChat ? 'mt-0' : 'mt-4'
						)}>
							<label
								style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
								className={cn(
									(inputsDisabled || isVideoRecordingPressed || isAudioRecordingPressed) && "opacity-50",
									"flex items-center justify-center cursor-pointer p-2 text-white transition-all ease-in-out rounded-full min500:mt-px",
								)}
							>
								<input
									disabled={inputsDisabled || isVideoRecordingPressed || isAudioRecordingPressed}
									onChange={handleFileupload}
									accept=".jpg, .jpeg, .mp4"
									className="hidden"
									type="file"
									name=""
									id=""
								/>
								<PhotoIcon className="text-white size-6" />
							</label>
							<Toggle
								pressed={isVoiceBoxOpen}
								onPressedChange={showVoiceInput}
								variant="outline"
								style={{ backgroundColor: themeData?.primary || '#096846' }}
								className={cn(
									"relative p-2 text-white rounded-full hover:text-white hover:shadow",
								)}
							>
								<MicrophoneIcon className={cn(isAudioRecordingPressed && "animate-pulse", 'size-6')} />
							</Toggle>
						</div>
					)}
				</div>
				:
				<div className={cn("justify-between items-center w-full min500:bg-white min500:pt-6 min500:mb-6 gap-1 mb-3.5 h-[50px]",
					(isVoiceBoxOpen && audioAllowed) ? 'hidden' : 'flex', !isTestChat && 'px-2')}>
					<button
						onClick={() => { openVoiceBoxFunction(); startRecordingAudio(true) }}
						type="button"
						disabled={inputsDisabled || isVideoRecordingPressed || !audioAllowed}
						style={{ backgroundColor: themeData?.primary || '#096846' }}
						className="disabled:opacity-80 flex items-center justify-center w-full h-[50px] px-4 text-white transition-all ease-in-out rounded-full">
						{inputsDisabled ?
							<BouncingDots />
							:
							<MicrophoneIcon className='size-6' />
						}
					</button>
					<label
						style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
						className={cn(
							(inputsDisabled || isVideoRecordingPressed || isAudioRecordingPressed) && "opacity-80",
							"flex items-center justify-center cursor-pointer p-2 px-2 mt-1 text-white transition-all ease-in-out rounded-full min500:mt-px",
						)}
					>
						<input
							disabled={inputsDisabled || isVideoRecordingPressed || isAudioRecordingPressed}
							onChange={handleFileupload}
							accept=".jpg, .jpeg, .mp4"
							className="hidden"
							type="file"
							name=""
							id=""
						/>
						<PhotoIcon className="text-white size-6" />
					</label>
					<button
						onClick={showTextInput}
						disabled={inputsDisabled || isVideoRecordingPressed || isAudioRecordingPressed}
						style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
						type="button" className="flex items-center justify-center p-2 px-2 mt-1 text-white transition-all ease-in-out rounded-full disabled:opacity-80 min500:mt-px">
						<IconKeyboard size={24} />
					</button>
				</div>
			}

			{/* voice recording box */}
			{audioAllowed &&
				<div className={cn("absolute rounded-b-xl left-0 right-0 w-full h-fit min500:ml-0 transition-all ease-in-out duration-300",
					(isVoiceBoxOpen && audioAllowed) ? (isTestChat ? '-bottom-12' : '-bottom-0') : '-bottom-[50vh]',
					!isTestChat && 'ml-[0.4rem]'
				)}>
					<div
						style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
						className={cn(
							"overflow-hidden max-w-[984px] min500:w-full max500:mb-4 rounded-b-2xl relative h-[40vh] items-center justify-center w-full flex text-white",
							!isTestChat && 'min500:rounded-b-none lg1000:w-[98.2%] md800:w-[97.7%]'
						)}
					>
						{/* close box - open voice button */}
						{!isAudioRecordingPressed && (
							<button
								onClick={() => {
									handleCancelVoiceMessage();
									setIsVoiceOpened(false);
									setIsVoiceBoxOpen(false);
									setCancelRecording(!cancelRecording)
								}}
								type="button"
								className="absolute z-50 p-1.5 text-white bg-white rounded-full top-5 right-4"
							>
								<IconX size={16} className="text-black" />
							</button>
						)}

						{/* white voice circle */}
						{isAudioRecordingPressed && (
							<div className="absolute">
								<div>
									<div
										ref={volumeRef}
										className={cn("absolute translate-x-1/2 -translate-y-1/2 rounded-full bg-white/30 top-1/2 right-1/2")}
										style={{ width: circleWidth, height: circleWidth }}
									></div>
								</div>
							</div>
						)}

						{/* stop and play buttons */}
						{!inputsDisabled && !audioReadyToSend &&
							isAudioRecordingPressed &&
							<button
								type="button"
								onClick={stopRecordingAudio}
								className="z-30 flex items-center justify-center gap-2 text-lg font-medium text-center cursor-pointer"
							>
								<span className="p-1 text-white border border-2 rounded-full">
									<IconPlayerStop size={28} />
								</span>
								Tap to stop recording
							</button>
						}
						{/* record again or cancel */}
						{!inputsDisabled && audioReadyToSend && (
							<div className="relative items-center justify-center w-full h-full">
								<button
									type="button"
									onClick={sendRecordingAudio}
									className="absolute z-30 flex items-center justify-center gap-2 text-lg font-medium -translate-x-1/2 -translate-y-1/2 cursor-pointer top-1/2 left-1/2"
								>
									<span className="p-1 text-white border border-2 rounded-full">
										<IconPlayerPlay size={28} />
									</span>
									Tap to send
								</button>
								<div className="absolute flex items-center gap-5 -translate-x-1/2 bottom-6 left-1/2">
									<button
										type="button"
										onClick={() => {
											handleCancelVoiceMessage();
											setIsVoiceOpened(false);
											setIsVoiceBoxOpen(false);
											setCancelRecording(!cancelRecording)
										}}
									>
										Cancel
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			}
		</form>
	);
}

export default ChatInput;