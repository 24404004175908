import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useBlocker, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from '@/axios/axiosInstance';
import ProjectShareLink from '@/components/projects/single-project/share/ProjectShareLink';
import ProjectShareMaxRespondets from '@/components/projects/single-project/share/ProjectShareMaxRespondents';
import ProjectSharePanel, { IPanelList } from '@/components/projects/single-project/share/ProjectSharePanel';
import useErrorStore from '@/store/ErrorStore';
import { EStatusCode } from '@/enums';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import ProjectShareTestLink from '@/components/projects/single-project/share/ProjectShareTestLink';
import UnsavedChangesModal from '@/components/Modals/UnsavedChangesModal';
import useCreateProjectStore from '@/store/CreateProjectStore';
import usePanelStore from '@/store/PanelStore';

function ProjectShare() {
  const { project_id } = useParams();
  const { setShowError } = useErrorStore();
  const { isFormChanged } = useCreateProjectStore();
  const { setShowSelectedPanel } = usePanelStore();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isFormChanged
      && currentLocation.pathname !== nextLocation.pathname,
  );

  const { data: singleProjectData, error: projectDataError, isLoading } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  useEffect(() => {
    if (singleProjectData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedPanel = Object.values(singleProjectData?.panel)?.find((panel: any) => panel.is_selected) as IPanelList;
      const panelEntries = Object.entries(singleProjectData?.panel);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const selectedPanelKey = panelEntries?.find(([_, value]) => value === selectedPanel)?.[0];
      setShowSelectedPanel(selectedPanelKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleProjectData]);

  if (blocker.state === 'blocked') return <UnsavedChangesModal blocker={blocker} />;

  if (isLoading) return <FullPageLoader />;

  return (
    <main className="flex flex-col w-full h-full mx-auto max-w-[1300px]">
      <div className="pt-5 pb-10">
        <ProjectShareTestLink singleProjectData={singleProjectData} />
      </div>
      <div className="py-5">
        <ProjectShareMaxRespondets singleProjectData={singleProjectData} />
      </div>
      <div className="pb-10">
        <ProjectSharePanel singleProjectData={singleProjectData} />
      </div>
      <div className="pb-10">
        <ProjectShareLink singleProjectData={singleProjectData} />
      </div>
    </main>
  );
}

export default ProjectShare;
