import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconInfoCircle, IconLoader2, IconPlus, IconTrash } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import ToolTip from '@/components/tooltip/ToolTip';
import { LangContext } from '@/context/LangProvider';
import { useMutateProjectUpdatePanels } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus, EStatusCode } from '@/enums';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import Switch from '@/components/switch/Switch';
import { Button } from '@/components/ui/button';
import usePanelStore from '@/store/PanelStore';
import useCreateProjectStore from '@/store/CreateProjectStore';

const validationSchema = Yup.object().shape({
  panel: Yup.array().of(
    Yup.object().shape({
      confirmation_link: Yup.string().required('Confirmation link is required').url('Please enter a valid URL'),
      is_selected: Yup.boolean(),
      provider_name: Yup.string().required('Provider name is required'),
    }),
  ).required('At least one panel is required'),
});

interface IProjectPanel {
  singleProjectData: IProjectProps;
}

export interface IPanelList {
  confirmation_link: string;
  provider_name: string;
  is_selected?: boolean;
}

type FormData = {
  panel: IPanelList[];
};

function ProjectSharePanel({ singleProjectData }: IProjectPanel) {
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const isPanelEmpty = singleProjectData?.panel && Object.keys(singleProjectData.panel).length !== 0;
  const [isIntegrated, setIsIntegrated] = useState(isPanelEmpty);
  const { currentTheme } = useTheme();
  const transformedPanelsData: IPanelList[] = Object.values(singleProjectData?.panel);
  const [panelList, setPanelList] = useState<IPanelList[]>(transformedPanelsData || []);
  const disabled = isUserViewer || isProjectPublished || isProjectCompleted;
  const { setShowSelectedPanel } = usePanelStore();
  const { setIsFormChanged, isFormChanged } = useCreateProjectStore();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: { panel: panelList },
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = useMutateProjectUpdatePanels();

  const toCamelCase = (str: string) => str?.toLowerCase().replace(/[^a-z0-9]+(.)/g, (_, chr) => chr.toUpperCase()).trim();

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const providerNames = data.panel.map((panel) => toCamelCase(panel.provider_name));
    const hasDuplicates = providerNames.some(
      (name, index) => providerNames.indexOf(name) !== index,
    );

    if (hasDuplicates) {
      toast({ description: lang.get('msg.providersNameMustBeUnique'), variant: 'destructive' });
      return;
    }

    const formattedData = data.panel.reduce((acc, panel) => {
      acc[toCamelCase(panel.provider_name)] = panel;
      return acc;
    }, {} as { [key: string]: IPanelList });

    const formattedDataWithPanel = { panel: formattedData };

    try {
      await mutateAsync({ data: formattedDataWithPanel, project_id });
      setIsFormChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const handleFormChanged = () => {
    setIsFormChanged(true);
  };

  const addPanel = () => {
    const newPanel = {
      is_selected: false,
      confirmation_link: '',
      provider_name: '',
    };

    setPanelList((prevList) => [...prevList, newPanel]);
    handleFormChanged();
  };

  const removePanel = (index: number) => {
    const updatedPanelList = panelList.filter((_, i) => i !== index);
    setPanelList(updatedPanelList);
    setValue('panel', updatedPanelList);
    handleFormChanged();
  };

  const handleToggle = async () => {
    try {
      if (isIntegrated) {
        await mutateAsync({ data: { panel: {} }, project_id });
        setPanelList([]);
      } else {
        addPanel();
      }
      handleFormChanged();
      setIsIntegrated(!isIntegrated);
      setIsFormChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const updatePanelField = (index: number, field: keyof IPanelList, value: string) => {
    setPanelList((prevList) => prevList.map((panel, i) => (i === index ? { ...panel, [field]: value } : panel)));
    handleFormChanged();
  };

  const panelIsEmpty = panelList[0]?.provider_name === '' || panelList[0]?.confirmation_link === '';

  useEffect(() => {
    setValue('panel', panelList);
  }, [panelList, setValue]);

  return (
    <div className="w-full h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6 pb-10">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1 w-fit">
                <ToolTip text="Select panel integration" className="ml-5" />
                <p className="flex items-center gap-1 mr-2 text-base font-medium">
                  {lang.get('msg.panelIntegration')}
                </p>
                <Switch func={handleToggle} value={isIntegrated} isDisabled={disabled} />
              </div>
              {isIntegrated && (
                <p className="flex items-center gap-1 text-sm">
                  <IconInfoCircle size={15} />
                  {lang.get('msg.panelIntegrationDescription')}
                </p>
              )}
            </div>
          </div>
        </div>

        {isIntegrated && (
          <div className="flex flex-col w-full gap-5">
            <div
              className="flex flex-col py-5 rounded-lg"
              style={{ backgroundColor: currentTheme?.['secondary-background'] }}
            >
              {React.Children.toArray(panelList?.map((item, index) => (
                <div className="flex flex-col w-full gap-3">
                  <div className="flex w-full gap-1 px-6 py-4 rounded-md">
                    <div className="flex items-start w-full gap-6 text-dark-text">
                      {!panelIsEmpty && !isFormChanged && (
                        <input
                          type="radio"
                          name={`panel[${index}].is_selected`}
                          checked={item.is_selected}
                          onChange={async () => {
                            const updatedPanelList = panelList.map((panel, i) => ({
                              ...panel,
                              is_selected: i === index,
                            }));

                            setPanelList(updatedPanelList);
                            setValue('panel', updatedPanelList);

                            const formattedData = updatedPanelList.reduce((acc, panel) => {
                              acc[toCamelCase(panel.provider_name)] = panel;
                              return acc;
                            }, {} as { [key: string]: IPanelList });

                            const formattedDataWithPanel = { panel: formattedData };
                            try {
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              const selectedPanel = Object.values(formattedDataWithPanel?.panel)?.find((panel: any) => panel.is_selected) as IPanelList;
                              const panelEntries = Object.entries(formattedDataWithPanel?.panel);
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const selectedPanelKey = panelEntries.find(([_, value]) => value === selectedPanel)?.[0];

                              setShowSelectedPanel(selectedPanelKey);
                              if (singleProjectData?.status !== EProjectStatus.DRAFT && singleProjectData?.status !== EProjectStatus.READY) return;
                              await mutateAsync({ data: formattedDataWithPanel, project_id });
                              setIsFormChanged(false);
                            } catch {
                              toast({ description: lang.get('msg.errorPleaseTryAgain') });
                            }
                          }}
                          disabled={isUserViewer || isProjectCompleted}
                          className="w-5 h-5 mt-8"
                        />
                      )}
                      <label className="flex flex-col w-1/2 gap-1">
                        <p
                          style={{ color: currentTheme?.['primary-text'] }}
                          className="text-sm font-medium"
                        >
                          Provider name
                          <span className="ml-0.5 text-red-600">*</span>
                        </p>
                        <Input
                          value={item.provider_name}
                          hasError={!!errors.panel?.[index]?.provider_name?.message}
                          placeholder={lang.get('msg.providerName')}
                          {...register(`panel.${index}.provider_name`, {
                            onChange: (e) => updatePanelField(index, 'provider_name', e.target.value),
                          })}
                          disabled={disabled}
                        />
                        <p
                          className={cn(
                            errors.panel?.[index]?.provider_name?.message ? 'flex' : 'hidden',
                            'text-red-600 text-xs font-semibold',
                          )}
                        >
                          {errors.panel?.[index]?.provider_name?.message}
                        </p>
                      </label>
                      <label className="flex flex-col w-1/2 gap-1">
                        <p
                          style={{ color: currentTheme?.['primary-text'] }}
                          className="text-sm font-medium"
                        >
                          Confirmation link
                          <span className="ml-0.5 text-red-600">*</span>
                        </p>
                        <Input
                          value={item.confirmation_link}
                          hasError={!!errors.panel?.[index]?.confirmation_link?.message}
                          placeholder={lang.get('msg.confirmationLink')}
                          {...register(`panel.${index}.confirmation_link`, {
                            onChange: (e) => updatePanelField(index, 'confirmation_link', e.target.value),
                          })}
                          disabled={disabled}
                        />
                        <p
                          className={cn(
                            errors.panel?.[index]?.confirmation_link?.message ? 'flex' : 'hidden',
                            'text-red-600 text-xs font-semibold',
                          )}
                        >
                          {errors.panel?.[index]?.confirmation_link?.message}
                        </p>
                      </label>
                      {!disabled && (
                        <div className="mt-5 ml-auto cursor-pointer">
                          <IconTrash
                            size={20}
                            onClick={() => removePanel(index)}
                            className="mt-4 text-red-600"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )))}
            </div>
            {!disabled && (
              <div className="flex items-center w-full gap-3">
                <Button
                  type="button"
                  onClick={addPanel}
                  variant="outline"
                  size="sm"
                  disabled={disabled}
                  className="w-full text-dark-text"
                >
                  <IconPlus size="1rem" />
                  Add Panel
                </Button>
                {isFormChanged && (
                  <Button variant="default" size="sm" className="w-full" type="submit">
                    {isSubmitting && <IconLoader2 className="w-5 h-5 animate-spin" />}
                    {lang.get('msg.save')}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default ProjectSharePanel;
