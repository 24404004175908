import { useContext, useEffect, useRef, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import { ECompanyThemeName, EProjectStatus } from '@/enums';

interface IProjectShare {
  singleProjectData: IProjectProps;
}

function ProjectShareTestLink({ singleProjectData }: IProjectShare) {
  const { currentTheme, themeName } = useTheme();
  const { lang } = useContext(LangContext);
  const inputRefSandbox = useRef<HTMLInputElement>(null);
  const { project_id } = useParams();
  const [projectSandboxLink, setProjectSandboxLink] = useState('');

  const PROJECT_STATUS = singleProjectData?.status;
  const showSandboxLink = PROJECT_STATUS !== EProjectStatus.DRAFT && PROJECT_STATUS !== EProjectStatus.COMPLETED;

  const handleCopyClickSandbox = async () => {
    if (inputRefSandbox.current) {
      try {
        await navigator.clipboard.writeText(inputRefSandbox.current.value);
        toast({ description: `✅ ${lang.get('msg.linkCopied')}!` });
      } catch (err) {
        toast({ title: `${lang.get('msg.linkNotCopied')}`, variant: 'destructive' });
      }
    }
  };

  useEffect(() => {
    const SANDBOX_PROJECT_LINK = `${import.meta.env.VITE_ENVIRONMENT_CHAT}/${project_id}?environment=sandbox`;
    const SANDBOX_PROJECT_LINK_EMPATHY = `${import.meta.env.VITE_ENVIRONMENT_CHAT_EMPATHY}/${project_id}?environment=sandbox`;

    const linkSandbox = themeName === ECompanyThemeName.EMPATHY ? SANDBOX_PROJECT_LINK_EMPATHY : SANDBOX_PROJECT_LINK;

    setProjectSandboxLink(linkSandbox);
  }, [themeName, project_id]);

  return (
    <div className="flex flex-col w-full gap-5">
      {/* INFO MESSAGE */}
      {!showSandboxLink && PROJECT_STATUS !== EProjectStatus.COMPLETED && (
        <div className="flex flex-col w-full gap-2">
          <p className="text-sm flex items-center gap-1.5 font-medium">
            <IconInfoCircle size={17} style={{ color: currentTheme?.primary }} />
            Test link will be generated once all of the required project fields are fulfilled.
          </p>
        </div>
      )}

      {/* SANDBOX TEST CHAT */}
      {showSandboxLink && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col w-full gap-1">
            <p className="text-base font-medium">
              Test link
            </p>
            <p
              style={{ color: currentTheme?.['primary-text'] }}
              className="text-sm flex items-center gap-1.5 font-medium"
            >
              <IconInfoCircle
                size={17}
                style={{ color: currentTheme?.primary }}
              />
              This test link is solely for testing purposes. Data from test conversations will be excluded from any analysis.
            </p>
          </div>

          <div
            style={{ backgroundColor: currentTheme?.['secondary-background'] }}
            className="flex flex-col items-center justify-center w-full gap-6 p-4 rounded-md"
          >
            <div className="flex w-full">
              <Input
                type="text"
                ref={inputRefSandbox}
                readOnly
                defaultValue={projectSandboxLink}
                className="rounded-tr-none rounded-br-none"
              />
              <Button
                variant="default"
                onClick={handleCopyClickSandbox}
                className="border-none rounded-tl-none rounded-bl-none"
              >
                Copy test link
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectShareTestLink;
