import { useEffect, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';
import ProjectBrandingChatPreview from './CreateProjectBrandingModal/ProjectBrandingChatPreview';
import ProjectBrandingConsentPreview from './CreateProjectBrandingModal/ProjectBrandingConsentPreview';
import ProjectBrandingInputs from './CreateProjectBrandingModal/ProjectBrandingInputs';
import { toast } from '../ui/use-toast';
import { useMutateCreateProjectTheme, useMutateUpdateProjectTheme } from '@/reactQuery/post';
import { ECreateThemeModalType, EStatusCode } from '@/enums';
import axiosInstance from '@/axios/axiosInstance';
import useTranslation from '@/hooks/useTranslation';

interface IModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateProjectBrandingModal({ openModal, setOpenModal }: IModalProps) {
  const { lang } = useTranslation();
  const { isUserAdmin, isUserOwner } = useOrganizationRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [brandingName, setBrandingName] = useState('');
  const [handleLogo, setHandleLogo] = useState<string | null>(null);
  const [primary1Color, setPrimary1Color] = useState('#096846');
  const [primary2Color, setPrimary2Color] = useState('#FF6F98');
  const [secondaryColor, setSecondaryColor] = useState('#FFE8ED');
  const [backgroundColor, setBackgroundColor] = useState('#F3F4F6');
  const [progressBarColor, setProgressBarColor] = useState('#FF6F98');
  const [consentBackgroundColor, setConsentBackgroundColor] = useState('#FF6F98');
  const { organization_id } = useParams();
  const modal_mode = searchParams.get('mode');
  const theme_id = searchParams.get('id');

  const { data: currentThemeColors } = useQuery({
    queryFn: () => axiosInstance.get(`/themes/${theme_id}`),
    queryKey: ['current_branding_data', { organization_id, theme_id, modal_mode, openModal }],
    enabled: !!organization_id && modal_mode === ECreateThemeModalType.EDIT && !!theme_id,
    select: (data) => data?.data,
  });

  const themeIsDefault = currentThemeColors?.name === 'Default';

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const removeQueryParams = (keys: string[]) => {
    keys.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  const resetColorsToDefault = () => {
    setBrandingName('');
    setHandleLogo(null);
    setPrimary1Color('#096846');
    setPrimary2Color('#FF6F98');
    setSecondaryColor('#FFE8ED');
    setBackgroundColor('#F3F4F6');
    setProgressBarColor('#FF6F98');
    setConsentBackgroundColor('#FF6F98');
  };

  const handleExitModal = () => {
    setOpenModal(false);
    removeQueryParams(['mode', 'id']);
  };

  const handleShowModal = () => {
    addQueryParams({ mode: 'create' });
    setOpenModal(true);
    resetColorsToDefault();
  };

  const { mutateAsync: mutateAsyncCreateTheme } = useMutateCreateProjectTheme();
  const { mutateAsync: mutateAsyncUpdateTheme } = useMutateUpdateProjectTheme();

  const onSubmit = async () => {
    try {
      if (!brandingName) {
        toast({
          title: 'Branding name missing',
          description: 'Please add your branding name to be able finish.',
          variant: 'destructive',
        });
        return;
      }

      const transformedData: ICreateThemeData = {
        name: brandingName,
        organization_id,
        primary: primary1Color,
        'chat-secondary': secondaryColor,
        'chat-primary': primary2Color,
        'chat-background': backgroundColor,
        'chat-consent-bg': consentBackgroundColor,
        'chat-progress-bar-bg': progressBarColor,
      };

      if (modal_mode === ECreateThemeModalType.CREATE) {
        await mutateAsyncCreateTheme(transformedData);
        toast({
          description: lang.get('msg.themeSuccessfullyCreated'),
        });
      }
      if (modal_mode === ECreateThemeModalType.EDIT) {
        toast({
          description: lang.get('msg.themeSuccessfullyUpdated'),
        });
        await mutateAsyncUpdateTheme({ formData: transformedData, theme_id });
      }

      handleExitModal();
    } catch (err) {
      const axiosError = err as AxiosError;

      if (axiosError?.status === 409) {
        toast({
          description: lang.get('msg.themeWithSameNameExists'),
          variant: 'destructive',
        });
        return;
      }

      if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({
          description: lang.get('msg.invalidRole'),
          variant: 'destructive',
        });
        return;
      }

      toast({
        description: lang.get('msg.errorPleaseTryAgain'),
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    if (modal_mode === ECreateThemeModalType.EDIT && currentThemeColors && theme_id) {
      setBrandingName(currentThemeColors.name || '');
      setPrimary1Color(currentThemeColors.primary || '#096846');
      setPrimary2Color(currentThemeColors['chat-primary'] || '#FF6F98');
      setSecondaryColor(currentThemeColors['chat-secondary'] || '#FFE8ED');
      setBackgroundColor(currentThemeColors['chat-background'] || '#F3F4F6');
      setProgressBarColor(currentThemeColors['chat-progress-bar-bg'] || '#FF6F98');
      setConsentBackgroundColor(currentThemeColors['chat-consent-bg'] || '#FF6F98');
    } else if (modal_mode === ECreateThemeModalType.CREATE) {
      resetColorsToDefault();
    }
  }, [currentThemeColors, theme_id, modal_mode]);

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger asChild onClick={handleShowModal}>
        <Button disabled={!isUserAdmin && !isUserOwner} variant="default" className="flex items-center gap-1">
          <IconPlus size={18} />
          Add new branding
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-full flex flex-col max-w-[95%] h-[95%]">
        <AlertDialogHeader className="w-full h-full overflow-y-auto">
          <AlertDialogTitle className="mb-4">{lang.get('msg.designYourBrandsColorPalette')}</AlertDialogTitle>
          <div className="flex w-full h-full gap-6 p-1.5">
            {/* editing */}
            <ProjectBrandingInputs
              brandingName={brandingName}
              setBrandingName={setBrandingName}
              primary1Color={primary1Color}
              primary2Color={primary2Color}
              backgroundColor={backgroundColor}
              consentBackgroundColor={consentBackgroundColor}
              progressBarColor={progressBarColor}
              secondaryColor={secondaryColor}
              setBackgroundColor={setBackgroundColor}
              setConsentBackgroundColor={setConsentBackgroundColor}
              setOpenModal={setOpenModal}
              setPrimary1Color={setPrimary1Color}
              setPrimary2Color={setPrimary2Color}
              setProgressBarColor={setProgressBarColor}
              setSecondaryColor={setSecondaryColor}
              handleExitModal={handleExitModal}
              onSubmit={onSubmit}
              themeIsDefault={themeIsDefault}
            />

            {/* previews */}
            <div
              style={{ backgroundColor }}
              className="flex items-center gap-8 h-full justify-center overflow-hidden w-[70%] min-w-[800px] p-5 rounded-lg shadow-sm"
            >
              {/* consent screen */}
              <ProjectBrandingConsentPreview
                consentBackgroundColor={consentBackgroundColor}
                handleLogo={handleLogo}
                primary1Color={primary1Color}
              />

              {/* chat screen */}
              <ProjectBrandingChatPreview
                primary1Color={primary1Color}
                primary2Color={primary2Color}
                progressBarColor={progressBarColor}
                secondaryColor={secondaryColor}
              />
            </div>
          </div>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default CreateProjectBrandingModal;
