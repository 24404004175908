import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IconX } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import useTheme from 'chat-ui/src/hooks/useTheme';
import useTranslation from '@/hooks/useTranslation';
import Routes from '@/router/routes';
import axiosInstance from '@/axios/axiosInstance';
import { ECompanyThemeName } from '@/enums';

function FreemiumTooltip() {
  const { lang } = useTranslation();
  const { organization_id } = useParams();
  const [showTooltip, setShowTooltip] = useState<boolean | null>(null);
  const { themeName } = useTheme();

  const { data: orgData, isLoading } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get/${organization_id}`),
    queryKey: ['current_organization_data', { organization_id }],
    select: (data) => data.data,
  });

  const isSubscribed = orgData?.subscription?.subscribed;

  useEffect(() => {
    const tooltipClosed = Cookies.get('freemiumTooltip');
    if (tooltipClosed) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  }, []);

  const handleTooltip = () => {
    setShowTooltip(false);

    Cookies.set('freemiumTooltip', 'true', { expires: 7 });
  };

  if (showTooltip === null || isLoading) return null;
  if (!showTooltip || isSubscribed) return null;
  if (themeName !== ECompanyThemeName.DEFAULT) return null;

  return (
    <div
      style={{
        boxShadow: '0 5px 10px rgba(255, 111, 152, 0.6)',
      }}
      className="flex items-center mb-7 flex-wrap relative gap-3 rounded-lg justify-center w-full h-fit py-3 px-8 border-chat-primary border-2 bg-[#FFE2F1]"
    >
      <p className="text-sm font-semibold">{lang.get('msg.freemiumTooltipDescription')}</p>
      <Link
        className="px-4 py-1 text-sm font-semibold text-white rounded-md bg-chat-primary hover:scale-[1.02] transition-all ease-in-out hover:bg-[#ed638a]"
        to={`${Routes.organization.path}/${organization_id}${Routes.choose_your_plan.path}`}
      >
        {lang.get('msg.upgradeNow')}
      </Link>
      <button
        type="button"
        onClick={handleTooltip}
        className="absolute -translate-y-1/2 right-2 top-1/2"
      >
        <IconX size={20} />
      </button>
    </div>
  );
}

export default FreemiumTooltip;
