import { useEffect, useState } from 'react';
import formatDate from '../../../lib/dateFormat';
import { cn } from '../../../lib/utils';
import MessageStatus from '../MessageStatus';
import MessageFactory from '../MessageFactory';
import ThreeDotLoader from '../../Loader/ThreeDotLoader';
import useChatStore from '../../../store';
import DOMPurify from "isomorphic-dompurify";
import getTextColor from '../../../hooks/getTextColorContrast';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import { useParams } from 'react-router-dom'


function TextMessage({
  text,
  data,
  created_at,
  seen,
  received,
  role,
  loading,
  hasError,
  isTestChat,
  isLastMessage,
}: TMessageData) {
  const { setIsLastMessageRerendered } = useChatStore((state) => state);
  const isUser = role === 'user';
  const date = formatDate(created_at, 'DD.MMM');
  const time = formatDate(created_at, 'hh:mm a');
  const { organization_id, project_id } = useParams()


  const formattedText = text?.replace(/\n/g, "<br>");
  const sanitizedFormattedText = DOMPurify.sanitize(formattedText || '');


  const [displayResponse, setDisplayResponse] = useState<string>('');
  const sanitizedDisplayResponse = DOMPurify.sanitize(displayResponse || '');

  const projectId = !!organization_id ? `/${project_id}` : window.location.pathname;

  const { data: themeData } = useQuery({
    queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get${projectId}/theme`),
    queryKey: ['chat_themes_branding', { projectId }],
    enabled: !!projectId,
    select: (data) => data?.data,
  });

  useEffect(() => {
    if (!formattedText?.length || !isLastMessage) {
      return;
    }

    let i = 0;
    let j = 0;
    const stringResponse = formattedText;
    const chunkSize = 35;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i + 1));

      i++;
      j++;

      if (j === chunkSize) {
        setIsLastMessageRerendered(String(i));
        j = 0;
      }

      if (i >= stringResponse.length) {
        clearInterval(intervalId);
        if (j !== i) {
          setIsLastMessageRerendered(String(i));
        }
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [formattedText, isLastMessage]);

  return (
    <div
      className={cn(
        'flex w-full self-end gap-3 md700:gap-1.5 max-w-[85%] min450:max-w-full transition-all',
        isUser ? 'self-end' : 'self-start',
      )}
    >
      <div className={cn('flex flex-col w-full', isUser && 'items-end w-full')}>
        <div
          style={{
            backgroundColor: isUser ? (themeData?.['chat-primary'] || '#FF6F98') : (themeData?.['chat-secondary'] || "#FFE8ED"),
            color: getTextColor((isUser ? (themeData?.['chat-primary'] || '#FF6F98') : (themeData?.['chat-secondary'] || "#FFE8ED")) || '#FFFFFF')
          }}
          className={cn(
            'w-fit px-3 py-2.5 font-medium md700:ml-0 text-[15px] shadow-md max-w-[85%] min450:max-w-[95%] relative',
            hasError && '!bg-red-400 border !text-white',
            isUser
              ? isTestChat ? "md600:ml-0 rounded-l-xl rounded-t-xl" : 'md600:ml-0 rounded-l-xl rounded-t-xl'
              : isTestChat ? 'md600:ml-0 rounded-br-xl rounded-t-xl' : 'md600:ml-0 rounded-br-xl rounded-t-xl',
          )}
        >
          {loading && (
            <div className="px-4 h-[22px] flex items-center">
              <ThreeDotLoader />
            </div>
          )}

          {isUser
            ? <p>{sanitizedFormattedText}</p>
            : isLastMessage && !loading ? <p className='[&_a]:underline min-h-[22px] [&_a]:text-blue-400'
              dangerouslySetInnerHTML={{ __html: sanitizedDisplayResponse }} /> : <p className='[&_a]:underline [&_a]:text-blue-400'
                dangerouslySetInnerHTML={{ __html: sanitizedFormattedText }} />
          }
          <div>{data?.map((item, index) => <div className='min-h-[22px]' key={index}>{MessageFactory({ ...item, isUser })}</div>)}</div>
        </div>
        <div className={cn('flex items-center', isUser ? 'justify-end' : 'justify-start')}>
          <span className=" mt-2 ml-1 text-[10px] text-gray-500">
            <span className="mr-1">{date}</span>
            {time}
          </span>
          {isUser && <MessageStatus seen={seen} received={received} />}
        </div>
      </div>
    </div>
  );
}

export default TextMessage;
