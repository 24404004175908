import { create } from 'zustand';

type State = {
  showSelectedPanel?: string;
};

type Action = {
  setShowSelectedPanel: (value?: string) => void;
};

const usePanelStore = create<State & Action>((set) => ({
  showSelectedPanel: '',
  setShowSelectedPanel: (value?: string) => set({ showSelectedPanel: value }),
}));

export default usePanelStore;
