import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

interface VideoJsPlayerProps {
  options: IVideoOptionsProps;
  onReady?: (player: ReturnType<typeof videojs>) => void;
}

export const VideoJsPlayer = ({ options, onReady }: VideoJsPlayerProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

  useEffect(() => {
    // Ensure Video.js player is initialized only once
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        // videojs.log('player is ready');
        onReady && onReady(player);
      }));
    } else if (playerRef.current) {
      // Update player if already initialized
      playerRef.current.autoplay(options.autoplay);
      playerRef.current.src(options.sources);
    }
  }, [options, onReady]);

  // Dispose the Video.js player when component unmounts
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div
      data-vjs-player
      style={{
        objectFit: 'cover',
        aspectRatio: '16 / 9',
      }}
      className="w-full h-auto mt-3"
    >
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJsPlayer;