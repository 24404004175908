import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Control, FieldValues, UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IconPlus } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { LangContext } from '@/context/LangProvider';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import RequiredLabel from '@/components/labels/RequiredLabel';
import QuestionBox from './QuestionBox';
import useCreateProjectStore from '@/store/CreateProjectStore';

interface IFileData {
  id: string;
  file: File | undefined;
}

interface IformValues {
  opening_message?: string;
  interview_questions?: {
    question?: string;
    media?: IMedia;
    follow_up_question?: string;
    answer_quantification?: {
      quantify_answer?: boolean;
      value?: string;
    }[];
    id: string;
  }[] | undefined;
}

interface IScriptProps {
  singleProjectData: IProjectProps;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setFileData: React.Dispatch<React.SetStateAction<{
    [key: string]: IFileData;
  }>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: any;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  fileData: {
    [key: string]: IFileData;
  };
}

function Script({ singleProjectData, isProjectPublished, isProjectCompleted, register, control, setValue, getValues,
  onSubmit, handleSubmit, setFileData, fileData }: IScriptProps) {
  const { lang } = useContext(LangContext);
  const { workspace_id } = useParams();
  const { setIsFormChanged, setReorderChanged } = useCreateProjectStore();
  const [questions, setQuestions] = useState<IQuestionBoxProps[]>(singleProjectData?.interview_questions || []);
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const [currentFormValues] = useState<IformValues>({});
  const [isActiveDrag, setIsActiveDrag] = useState(false);
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setQuestions((items) => {
        const activeIndex = items.findIndex((q) => q?.id === active?.id);
        const overIndex = items.findIndex((q) => q?.id === over?.id);

        const reorderedItems = arrayMove(items, activeIndex, overIndex);

        // Update the form values based on the reordered items
        const reorderedFormValues = reorderedItems.map((item) => {
          const matchingValue = currentFormValues?.interview_questions?.find(
            (q) => q?.id === item.id,
          );

          return matchingValue || item;
        });

        // Update the form values using setValue
        setValue('interview_questions', reorderedFormValues);
        setReorderChanged(true);

        return reorderedItems;
      });
    }

    setIsActiveDrag(false);
  };

  const addQuestion = () => {
    const newQuestion: IQuestionBoxProps = {
      id: uuidv4(),
      question: '',
      follow_up_question: '0',
      media: undefined,
      answer_quantification: [
        {
          quantify_answer: false,
          value: '',
        },
      ],
    };

    // Update the state
    setQuestions((prevQuestions) => [...(prevQuestions || []), newQuestion]);

    // Retrieve the current form values
    const currentFormValuesAdd = getValues();

    // Update the form values using setValue
    setValue('interview_questions', [
      ...(currentFormValuesAdd.interview_questions || []),
      {
        id: newQuestion.id,
        question: '',
        media: undefined,
        follow_up_question: '0',
        answer_quantification: [{
          quantify_answer: false,
          value: '',
        }],
      },
    ]);
    setOpenQuestionId(newQuestion.id);
    // Submitting the form instantly because if the user tries to upload media without submitting it first,
    // it will not work.
    handleSubmit(onSubmit)();
  };

  const handleDeleteQuestion = (idToDelete: string) => {
    // Update the state
    setQuestions((prevQuestions) => prevQuestions.filter((question) => question.id !== idToDelete));

    // Retrieve the current form values
    const currentFormValuesDelete = getValues();

    // Filter out the question to be deleted
    const updatedInterviewQuestions = currentFormValuesDelete.interview_questions?.filter(
      (q: { id: string }) => q.id !== idToDelete,
    ) || [];

    // Update the form values using setValue
    setValue('interview_questions', updatedInterviewQuestions);
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const initialInterviewQuestions: IQuestionBoxProps[] = singleProjectData?.interview_questions || [];
    setQuestions(initialInterviewQuestions);
  }, [singleProjectData]);

  return (
    <div className="w-full h-full pl-1 pb-14">
      <div className="flex flex-col w-full gap-6 pb-10">
        {/* -- introduction -- */}
        <RequiredLabel title="openingMessage" tooltip={lang.get('msg.openingMessageInfo')}>
          <Textarea
            disabled={isUserViewer || isProjectPublished || isProjectCompleted}
            {...register('opening_message')}
            placeholder={lang.get('msg.openingMessagePlaceholder')}
            onChange={() => setIsFormChanged(true)}
            className="h-24"
          />
        </RequiredLabel>

        {/* -- drag & drop area -- */}
        <div className="flex flex-col w-full h-full gap-4">
          <p className="flex items-center gap-1 text-[15px] font-medium">
            {lang.get('msg.interviewQuestions')}
          </p>
          {/* wrapper */}
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={() => setIsActiveDrag(true)}
          >
            <div className="flex flex-col w-full h-full gap-5 p-6 rounded-md bg-slate-50">
              <SortableContext
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                items={questions}
                strategy={verticalListSortingStrategy}
              >
                {questions.map((question, index) => (
                  <QuestionBox
                    isProjectPublished={isProjectPublished}
                    isProjectCompleted={isProjectCompleted}
                    isActiveDrag={isActiveDrag}
                    key={question.id}
                    index={index}
                    register={register}
                    id={question.id}
                    control={control}
                    handleDeleteQuestion={handleDeleteQuestion}
                    isUserViewer={isUserViewer}
                    existingText={question.question}
                    singleProjectData={singleProjectData}
                    questions={questions}
                    openQuestionId={openQuestionId}
                    setOpenQuestionId={setOpenQuestionId}
                    setFileData={setFileData}
                    fileData={fileData}
                  />
                ))}
              </SortableContext>
            </div>
          </DndContext>
          <div className="flex items-center justify-between w-full gap-2">
            {/* add question button */}
            <Button
              variant="default"
              disabled={isUserViewer || isProjectPublished || isProjectCompleted}
              type="button"
              onClick={addQuestion}
              className="flex items-center w-full gap-1"
            >
              <IconPlus size={16} />
              {lang.get('msg.addQuestion')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Script;
